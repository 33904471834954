import React from 'react'
import Layout from '../components/Layout'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
const Akademia = () => (
  <>
  <Helmet titleTemplate="Akademia BEOMNI | Zostań mistrzem e-commerce | Wrocław">
    <title>Akademia BEOMNI | Zostań mistrzem e-commerce | Wrocław</title>
    <meta name="description" content={'Jeżeli chcesz poznać tajniki skutecznej sprzedaży internetowej i osiągnąć - sukces w e-commerce to już dziś zapisz się do Akademii BEOMNI!'}/>
    <meta name='image' content={'https://e-multicontent.pl/img/meta/akademia-meta.jpg'} />
    <meta name='keywords' content={'copywriting, copywriterzy, marketing, agencja copywriterska, Wrocław, zespół copywriterów, praca, biznes, treści, content, tłumaczenia, SEO, blog, teksty, artykuły, grafika, video, strony internetowe, sklepy internetowe, marketplace, e-commerce'} />

    <meta property="og:description" content={'Jeżeli chcesz poznać tajniki skutecznej sprzedaży internetowej i osiągnąć - sukces w e-commerce to już dziś zapisz się do Akademii BEOMNI!'}/>
    <meta property="og:title" content={'Akademia BEOMNI | Zostań mistrzem e-commerce | Wrocław'} />
    <meta property="og:image" content={'https://e-multicontent.pl/img/meta/akademia-meta.jpg'} />
    <meta property="og:url" content={'https://e-multicontent.pl/akademia-beomni-e-commerce/'}/>

    <meta name="twitter:card" content="summary_large_image" />
    <meta property="twitter:domain" content="e-multicontent.pl" />
    <meta property="twitter:url" content="https://e-multicontent.pl/akademia-beomni-e-commerce/" />
    <meta name="twitter:title" content="Akademia BEOMNI | Zostań mistrzem e-commerce | Wrocław" />
    <meta name="twitter:description" content="Jeżeli chcesz poznać tajniki skutecznej sprzedaży internetowej i osiągnąć - sukces w e-commerce to już dziś zapisz się do Akademii BEOMNI!" />
    <meta name="twitter:image" content="https://e-multicontent.pl/img/apple-touch-icon.png" />
    <script type='application/ld+json'>
    {JSON.stringify({
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://e-multicontent.pl/"
      },{
        "@type": "ListItem",
        "position": 2,
        "name": "Akademia BEOMNI",
        "item": "https://e-multicontent.pl/akademia-beomni-e-commerce/"
      }]
    })}
    </script>
    <meta name="robots" content="noindex" />
  </Helmet>
  <Layout>
  <nav className="breadcrumbs-nav section">
      <Link className="inline-link" to="/">Home</Link>&nbsp;&gt;&nbsp;<span style={{fontWeight:'600'}}><b>Akademia BEOMNI</b></span>
  </nav>
  <div style={{textAlign:'center',backgroundColor:'#36b3d2'}}>
  <br /><br />
  <img loading="lazy" src="/img/oferty/o17.svg" style={{width:'150px'}} alt="akademia BEOMNI marketplace / e-commerce" />
  <br />
  <h1 className="title" style={{color:'white'}}>
  AKADEMIA BEOMNI – Wrocław</h1>
  <h2 className="subtitle" style={{color:'white'}}>
  Zostań mistrzem e-commerce!</h2>
  <br />
  </div>
  <section className="section" style={{marginLeft:'3%',marginRight:'3%'}}>
  <div style={{marginLeft:'5%',marginRight:'5%'}} className="content">
  <p className="subtitle" style={{borderLeft: '10px solid rgb(54, 179, 210)',paddingLeft:'10px'}}>W czasach starożytnych mówiło się, że „wszystkie drogi prowadzą do Rzymu”.
  Jeżeli myślisz, że to stare przysłowie nie ma żadnego związku z e-commerce to
  jesteś w poważnym błędzie! Przyszłością handlu internetowego jest bowiem
  <strong style={{textTransform:'uppercase'}}> omnichannel</strong> – integracja wielu różnych kanałów sprzedaży w celu ułatwienia
  klientom zakupów, zwiększenia ich lojalności i poprawy wiarygodności marki.</p>

  <p>Inaczej mówiąc: strategia BEOMNI sprawi, że… wszystkie możliwe drogi
  doprowadzą Klienta właśnie do Ciebie. <br />Szybko, wygodnie, bezproblemowo i
  z dowolnego miejsca na świecie!</p>

  <h3>Jeżeli chcesz poznać tajniki skutecznej sprzedaży internetowej i osiągnąć
  sukces w e-commerce to już dziś <a href="#zapisz-sie">zapisz się</a> do <strong>Akademii BEOMNI</strong>! </h3>

  <p>Akademia BEOMNI to cykl warsztatów, przybliżających najważniejsze zasady
  wygodnej i efektywnej sprzedaży na globalnych i lokalnych platformach sprzedażowych.
  Zdobyta podczas szkoleń wiedza i umiejętności pozwolą Ci na udoskonalenie swojego biznesu,
  zwiększenie sprzedaży i pozyskanie grona lojalnych klientów. </p>

  <h3 style={{textAlign:'center'}}>
  <img loading="lazy" src="/img/oferty/o18.svg" style={{width:'150px'}} alt="zwiększenie obrotu rocznego marketplace / e-commerce" />
  <br /><br />
  Nie musisz nam jednak wierzyć na słowo!<br /> Liczby i doświadczenia firm
  korzystających ze strategii BEOMNI mówią same ze siebie:</h3>
  <ul>
  <li>Klient 1 (artykuły motoryzacyjne i dziecięce) – w ciągu 2,5 roku zwiększył
  obrót roczny z 6 do 17 milionów rocznie </li>
  <li> Klient 2 (rękawice lateksowe) – w ciągu 4 miesięcy od wkroczenia na rynek
  niemiecki 3 różnymi kanałami doszedł do obrotu w wysokości 70 000 EUR miesięcznie </li>
  <li>Klient 3 (moda) – w ciągu 9 miesięcy od uruchomienia 1 kanału na Amazon,
  docierającego do 3 różnych krajów, uzyskał obrót w wysokości 200 000 EUR miesięcy </li>
  </ul>

  <h3 style={{textAlign:'center'}}>
  Chcesz powtórzyć ich sukces i zdobyć sprzedażowy szczyt?<br /><a href="#zapisz-sie">Zapisz się na szkolenia w Akademii BEOMNI.</a></h3>
  <br />
  <h4>Akademia BEOMNI obejmuje 4 całodniowe spotkania, podczas których:</h4>
  <ul>
  <li>dowiesz się, dlaczego tak ważna jest dywersyfikacja kanałów sprzedaży w internecie,</li>
  <li>poznasz rozwiązania i narzędzia, pozwalające wygodnie zarządzać różnymi kanałami sprzedaży,</li>
  <li>dowiesz się, jak szybko i efektywnie skalować biznes,</li>
  <li>rozumiesz proces optymalizacji i automatyzacji procesów sprzedaży,</li>
  <li>dowiesz się, jak zarządzać magazynem i planami magazynowymi,</li>
  <li>nauczysz się tworzyć dobry product listing i skuteczne opisy produktów,</li>
  <li>poznasz zasady efektywnego kontaktu z klientem e-commerce,</li>
  <li>dowiesz się, jak prowadzić działania reklamowe i marketingowe na platformach sprzedażowych,</li>
  </ul>
  <br />
  <h4>5 najważniejszych powodów, dla których warto wziąć udział w Akademii BEOMNI:</h4>
  <ol>
  <li>4 dni szkoleniowe to aż 32 godziny praktycznej wiedzy i konkretnych umiejętności</li>
  <li>Każdy moduł składa się z części teoretycznej i warsztatowej</li>
  <li>Szkolenia prowadzone przez ekspertów e-commerce i content marketingu</li>
  <li>Zajęcia odbywają się małych grupach – od 2 do 6 osób</li>
  <li>Podczas zajęć pracujemy na prawdziwych przykładach i realnych sytuacjach</li>
  </ol>
  <p>Aby osiągnąć sukces na marketplace nie wystarczy zatem doskonały produkt!<br />
  Musisz dokładnie znać zasady działania platformy, perfekcyjnie wyeksponować
  swoją ofertę, a także zapewnić klientom najwyższy poziom obsługi.</p>
  <br />
  <h4 style={{textAlign:'center'}}>
  <img loading="lazy" src="/img/oferty/o11.svg" style={{width:'150px'}} alt="sukces w handlu marketplace / e-commerce" />
  <br /><br />
  Sukces w e-commerce jest na wyciągnięcie ręki. <br /> Już dziś <a href="#zapisz-sie">zapisz się na
  Akademię BEMONI</a>, zostań mistrzem sprzedaży internetowej i zostaw konkurencję daleko w tyle! </h4>
  <br />
  <h4 style={{textAlign:'center'}}>INFORMACJE ORGANIZACYJNE:</h4>
  <p style={{textAlign:'center'}}>Akademia BEOMNI odbywa się stacjonarnie we Wrocławiu w cyklu miesięcznym
  (1x w tygodniu, po 8 godzin dziennie).<br /> Organizatorem warsztatów jest <a href="https://setup.pl" rel="noopener noreferrer" target="_blank">SETUP.PL</a> we
  współpracy z <Link to="/">E-MULTICONTENT.PL</Link>.</p>
  <br />
  <h4>PROGRAM:</h4>
  <br />
  <h6>Moduł 1 - PLENTYMARKETS</h6>
  <ul>
  <li>Optymalizacja i automatyzacja procesów sprzedaży</li>
  <li>Zarządzanie magazynem i planami magazynowymi</li>
  </ul>
  <br />
  <h6>Moduł 2 - CUSTOMER SERVICE</h6>
  <ul>
  <li>3 kroki do efektywnego kontaktu z klientem e-commerce</li>
  </ul>
  <br />
  <h6>Moduł 3 - EKSPOZYCJA PRODUKTU NA PRZYKŁADZIE EBAY I AMAZON.</h6>
  <ul>
  <li>Jak stworzyć dobry Product Listing?</li>
  <li>Wyróżnij się albo zgiń - skuteczna ekspozycja produktu</li>
  <li>e-multicontent dla marketplace, czyli efektywne opisy w różnych językach</li>
  </ul>
  <br />
  <h6>Moduł 4 - MARKETING 3</h6>
  <ul>
  <li>Optymalizacja listingów i zarządzanie reklamą PPC na Amazonie</li>
  <li>Analiza TOP SPRZEDAŻY</li>
  </ul>
  <br />
  </div>
  </section>

  <section className="section content" style={{backgroundColor:'#36b3d2',paddingLeft:'5%',paddingRight:'5%'}}>
  <h3 style={{textAlign:'center',color:'white'}}>
  <img loading="lazy" src="/img/oferty/o13.svg" style={{width:'150px'}} alt="trenerzy akademii BEOMNI marketplace / e-commerce" />
  <br /><br />
  TRENERZY:</h3>
  <br /><br />
  <div className="columns">
    <div className="column" style={{textAlign:'right'}}>
    <h3 style={{color:'white'}}>Elżbieta Siewniak</h3>
    <p style={{color:'black'}}>Zorientowany na ludzi manager z doświadczeniem w zakresie zarządzania ludźmi,
    szczególnie w obszarze HR, Customer Care, administracji oraz projektach wolontariackich.
    Dzieli się doświadczeniem w budowaniu od podstaw procesów operacyjnych oraz w
    usprawnianiu istniejących. Pasjonatka wysokich standardów oraz różnorodności w miejscu pracy.
    Od kilku lat związana z branżą e-commerce, zwłaszcza w zakresie zarządzania klientem
    i optymalizowania operacyjności. Prywatnie działaczka społeczna, trenerka
    kompetencji międzykulturowych i wizjonerka, konsekwentnie zmieniająca świat na lepsze.</p>
    </div>
    <div className="column">
    <img loading="lazy" src="/img/akademia/ela.jpg" alt="Elżbieta Siewniak"  style={{width:'300px',border:'10px solid black',borderRadius:'10px'}} />
    </div>
  </div>


  <br /><br />
  <div className="columns">
    <div className="column" style={{textAlign:'right'}}>
    <img loading="lazy" src="/img/akademia/pawel.jpg" alt="Paweł Lach" style={{width:'300px',border:'10px solid black',borderRadius:'10px'}} />
    </div>
    <div className="column">
    <h3 style={{color:'white'}}>Paweł Lach</h3>
    <p style={{color:'black'}}>Wieloletni ekspert uruchomienia i optymalizacji stron produktów na marketplace’ach.
    W swojej codziennej pracy uruchamia nowe produkty na platformach, jak Amazon,
    eBay, Cdiscount, Allegro. Znając techniczne szczegóły oraz działanie algorytmów,
    tworzy w swojej codziennej pracy w Setup.pl dobrze konwertujące aukcje i listingi.</p>
    </div>
  </div>

  <br /><br />
  <div className="columns">
    <div className="column" style={{textAlign:'right'}}>
    <h3 style={{color:'white'}}>Łukasz Macioł</h3>
    <p style={{color:'black'}}>Instruktor szkoleniowy w Setup.pl, ekspert Plentymarkets, który w swojej
    codziennej pracy konfiguruje systemy Plentymarkets, integruje kanały sprzedaży,
    operatorów płatności oraz przewoźników. Doświadczony w tworzeniu automatyzacji
    oraz rozwoju modułu WMS w Plentymarkets.</p>
    </div>
    <div className="column">
    <img loading="lazy" src="/img/akademia/lukasz.jpg" alt="Łukasz Macioł" style={{width:'300px',border:'10px solid black',borderRadius:'10px'}} />
    </div>
  </div>

  <br /><br />
  <div className="columns">
    <div className="column" style={{textAlign:'right'}}>
    <img loading="lazy" src="/img/akademia/aga.jpg" alt="Agnieszka Filip" style={{width:'300px',border:'10px solid black',borderRadius:'10px'}} />
    </div>
    <div className="column">
    <h3 style={{color:'white'}}>Agnieszka Filip</h3>
    <p style={{color:'black'}}>Doświadczona managerka, specjalistka od e-PR i założycielka wielojęzykowej
    agencji copywriterskiej e-multicontent. Od 2012 roku aktywnie wspiera biznesy
    z branży e-commerce, pomagając im zaistnieć lub umocnić swoją pozycję w sieci,
    poprzez najwyższej jakości treści, w tym efektywne opisy produktów. W swojej
    pracy stawia na dialog, dobre relacje i wzajemne uzupełnianie się członków
    zespołu, a za największy atut swojej firmy uznaje połączenie umiejętności copywriterskich,
    kompetencji językowych i znajomości rynku docelowego z technikami SEO.
    Dowodem na skuteczność takiej strategii jest fakt, że w portfolio jej firmy
    znajdują się zarówno rodzinne marki i niszowi producenci, jak i największe
    sklepy internetowe oraz liderzy branży e-commerce, działający z sukcesami na
    wielu rynkach europejskich.</p>
    </div>
  </div>


  <br /><br />
  <div className="columns">
    <div className="column" style={{textAlign:'right'}}>
    <h3 style={{color:'white'}}>Marta Bilińska-Rochewicz</h3>
    <p style={{color:'black'}}>Specjalistka ds. content marketingu i copywriterka z wieloletnim doświadczeniem
    w tworzeniu tekstów marketingowych i sprzedażowych. Od dekady związana z e-commerce,
    działając między innymi na rzecz porównywarki cen Skąpiec.pl, serwisu z opiniami
    na temat sklepów internetowych Opineo.pl oraz podmiotów z grupy Ringier Axel
    Springer Polska. Obecnie współpracuje z marką e-multicontent – agencją,
    specjalizującą się treściach dla sklepów internetowych, w tym skutecznych
    opisów produktów w ponad 20 różnych językach.</p>
    </div>
    <div className="column">
    <img loading="lazy" src="/img/akademia/marta.jpg" alt="Marta Bilińska-Rochewicz" style={{width:'300px',border:'10px solid black',borderRadius:'10px'}} />
    </div>
  </div>

  <div id="zapisz-sie" style={{textAlign:'center',margin:'5%',paddingTop:'5%'}}>
  <a className="btnblog" href="https://app.evenea.pl/event/akademiabeomni/" rel="noopener noreferrer" target="_blank">Zapisz się</a>
  </div>

  </section>



  </Layout>
  </>
)

export default Akademia
